import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  addDoc,
  serverTimestamp,
  collection
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseConfig, firestore, auth, storage } from './firebase';
import uuid from 'react-native-uuid';
import {
  Image as ImageIcon,
  FileText as FileIcon,
  X,
  Plus,
  ArrowUp,
  Globe,
} from 'lucide-react';
import {
  getChatModelByModelString,
  isGoogle,
  getMaxTokens,
  getEndpoint,
  isImageGeneration,
  isOpenAIReasoning
} from './chatModels';
import { initializeApp } from 'firebase/app';
import { getVertexAI, getGenerativeModel } from 'firebase/vertexai-preview';

const MessageInput2 = ({
  selectedChat,
  selectedModel,
  selectedModelName,
  onMessageSent,
  messages = [],
  updateChatTitle,
  hasSubscription,
  hasBasicSubscription,
  messageLimit,
  disableInput = false,
}) => {
  // --------------------------------------------------
  // State Hooks
  // --------------------------------------------------
  const [input, setInput] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  const [remainingMessages, setRemainingMessages] = useState(messageLimit);
  const [imageBase64, setImageBase64] = useState(null);
  const [isOnline, setIsOnline] = useState(false);

  // --------------------------------------------------
  // Refs
  // --------------------------------------------------
  const lastUpdateRef = useRef(0);
  const updateIntervalRef = useRef(100);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  // --------------------------------------------------
  // Firebase initialization
  // --------------------------------------------------
  const firebaseApp = initializeApp(firebaseConfig);
  const vertexAI = getVertexAI(firebaseApp);
  const navigate = useNavigate();

  // --------------------------------------------------
  // Helpers
  // --------------------------------------------------
  const isSonnet = selectedModel === 'claude-3-5-sonnet-latest';
  const isGeminiFlash = selectedModel === 'gemini-2.0-flash-001';
  const isDeepseek = selectedModel?.includes('deepseek');
  const isOpenAIReasoningWithoutImage = selectedModel.includes('o3');
  const modelDetails = getChatModelByModelString(selectedModel);

  const sanitizeModelName = (modelName) => {
    return modelName.replace(/\./g, '_');
  };

  const checkAndUpdateUsage = async () => {
    if (!auth.currentUser) return false;
    try {
      const usageRef = doc(firestore, 'model_usage_by_user', auth.currentUser.uid);
      const usageDoc = await getDoc(usageRef);
      const today = new Date().toISOString().split('T')[0];
      const safeModelName = sanitizeModelName(selectedModel);

      if (!usageDoc.exists()) {
        try {
          await setDoc(usageRef, {
            [safeModelName]: 1,
            date: today,
          });
          return true;
        } catch (error) {
          console.error('Error creating usage document:', error);
          return true;
        }
      }

      const usageData = usageDoc.data();
      const storedDate = usageData.date;

      if (storedDate !== today) {
        try {
          await setDoc(usageRef, {
            [safeModelName]: 1,
            date: today,
          });
          return true;
        } catch (error) {
          console.error('Error resetting usage count:', error);
          return true;
        }
      }

      const currentUsage = usageData[safeModelName] || 0;
      if (currentUsage >= messageLimit) {
        return true;
      }

      // Update usage
      try {
        await updateDoc(usageRef, {
          [safeModelName]: currentUsage + 1,
        });
      } catch (error) {
        console.error('Error updating usage count:', error);
      }
      return true;
    } catch (error) {
      console.error('Error checking usage:', error);
      return true;
    }
  };

  const updateRemainingMessages = async () => {
    if (!auth.currentUser) return;
    try {
      const usageRef = doc(firestore, 'model_usage_by_user', auth.currentUser.uid);
      const usageDoc = await getDoc(usageRef);
      const today = new Date().toISOString().split('T')[0];
      const safeModelName = sanitizeModelName(selectedModel);

      if (!usageDoc.exists()) {
        setRemainingMessages(messageLimit);
        return;
      }

      const storedDate = usageDoc.data().date;
      if (storedDate !== today) {
        setRemainingMessages(messageLimit);
        return;
      }

      const currentUsage = usageDoc.data()[safeModelName] || 0;
      setRemainingMessages(Math.max(0, messageLimit - currentUsage));
    } catch (error) {
      console.error('Error updating remaining messages:', error);
      setRemainingMessages(messageLimit);
    }
  };

  useEffect(() => {
    updateRemainingMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel, messages, selectedChat, messageLimit]);

  // --------------------------------------------------
  // File & Image Helpers
  // --------------------------------------------------
  const convertToJPEG = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], 'image.jpg', {
              type: 'image/jpeg',
            });
            resolve(convertedFile);
          }, 'image/jpeg', 0.8);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const fileToGenerativePart = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({
          inlineData: {
            data: reader.result.split(',')[1],
            mimeType: file.type,
          },
        });
      };
      reader.readAsDataURL(file);
    });
  };

  const clearFileState = () => {
    setSelectedImage(null);
    setSelectedPdf(null);
    setImagePreviewUrl(null);
    setPdfPreviewUrl(null);
    setImageBase64(null);
    if (fileInputRef.current) fileInputRef.current.value = '';
  };

  // --------------------------------------------------
  // Send Message
  // --------------------------------------------------
  const handleSendMessage = useCallback(async () => {
    if (!input.trim() && !selectedImage && !selectedPdf) return;
    if (!auth.currentUser?.uid) {
      navigate('/welcome');
      return;
    }

    const canSendMessage = await checkAndUpdateUsage();
    if (!canSendMessage) {
      console.log('Message limit reached for today');
      return;
    }

    let fileUrl = '';
    let imageUrl = '';
    let imagePart = null;

    // The user message object
    const userMessage = {
      id: uuid.v4(),
      text: input,
      role: 'user',
      streaming: false,
      pending: true,
      localImage: true,
    };

    // If local image/pdf is displayed:
    if (imagePreviewUrl) {
      userMessage.imageUrl = imagePreviewUrl;
      userMessage.localImage = true;
    } else if (pdfPreviewUrl) {
      userMessage.pdfUrl = pdfPreviewUrl;
      userMessage.localPdf = true;
    }

    let chatId = selectedChat?.id;

    try {
      // Create new chat if needed
      if (!chatId) {
        const newChatRef = doc(collection(firestore, 'chats'));
        chatId = newChatRef.id;

        const modelName = selectedModelName || 'GPT 4o mini';
        await setDoc(newChatRef, {
          id: chatId,
          owner: auth.currentUser.uid,
          model: modelName,
          topic: 'Untitled Chat',
          lastMessageSent: serverTimestamp(),
        });
      }

      // Send the user message to the UI
      onMessageSent(userMessage, chatId);
      setInput('');
      clearFileState();

      // If there's an image or PDF, upload it
      if (selectedImage || selectedPdf) {
        const file = selectedImage || selectedPdf;
        const fileType = selectedImage ? 'image' : 'pdf';
        const fileRef = ref(
          storage,
          `chat_${fileType}s/${auth.currentUser.uid}/${Date.now()}_${fileType}.${fileType === 'image' ? 'jpg' : 'pdf'
          }`
        );

        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);

        if (fileType === 'image') {
          userMessage.imageUrl = fileUrl;
          imagePart = await fileToGenerativePart(file);
          userMessage.localImage = false;
        } else {
          userMessage.pdfUrl = fileUrl;
          userMessage.pdfName = file.name;
        }
        onMessageSent(userMessage, chatId);
      }

      // Store user message in Firestore
      await addMessageToFirestore(chatId, userMessage);
      userMessage.pending = false;
      onMessageSent(userMessage, chatId);

      // Generate assistant response
      const modelDetails = getChatModelByModelString(selectedModel);
      const assistantMessageId = uuid.v4();
      let accumulatedResponse = '';
      let accumulatedReasoning = ''; // <--- Accumulate reasoning in real-time
      let lastCitations = [];
      const conversationHistory = [...messages, userMessage];
      const maxTokens = getMaxTokens(selectedModel);

      const assistantMessage = {
        id: assistantMessageId,
        text: '',
        reasoning_text: '', // <--- Keep updating partial chain-of-thought
        reasoning_time: '',
        role: 'assistant',
        streaming: true,
      };

      const updateMessage = (text) => {
        const now = Date.now();
        if (now - lastUpdateRef.current >= updateIntervalRef.current) {
          // Update the text (assistant's main content) in UI
          assistantMessage.text = text.trim();
          onMessageSent(assistantMessage, chatId);
          lastUpdateRef.current = now;
        }
      };

      // 1) Google-based model
      if (isGoogle(modelDetails)) {
        const model = getGenerativeModel(vertexAI, { model: selectedModel });
        const chat = model.startChat({
          history: conversationHistory.map((msg) => ({
            role: msg.role === 'user' ? 'user' : 'model',
            parts: [{ text: msg.text }],
          })),
          generationConfig: {
            maxOutputTokens: maxTokens,
          },
        });

        const parts = [{ text: input }];
        if (imagePart) {
          parts.push(imagePart);
        }

        const result = await chat.sendMessageStream(parts);
        for await (const chunk of result.stream) {
          const chunkText = chunk.text();
          accumulatedResponse += chunkText;
          updateMessage(accumulatedResponse);
        }
      }
      // 2) Image generation model
      else if (isImageGeneration(modelDetails)) {
        const endpoint = getEndpoint(modelDetails, hasSubscription);
        const requestBody = { messages: conversationHistory };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const imageResponse = await fetch(endpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (imageResponse.ok) {
          const imageData = await imageResponse.json();
          imageUrl = imageData.image;

          assistantMessage.streaming = false;
          assistantMessage.text = imageUrl;
          onMessageSent(assistantMessage, chatId);

          await addMessageToFirestore(chatId, assistantMessage);
          await generateAndUpdateTopic(imageUrl, chatId);
        } else {
          console.error('Image generation failed');
        }
      } else if (isOpenAIReasoning(modelDetails)) {
        const requestBody = {
          messages: conversationHistory,
          model: selectedModel,
          imageBase64: imageBase64,
          max_tokens: maxTokens,
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const baseEndpoint = getEndpoint(modelDetails, hasSubscription);
        const finalEndpoint = isOnline ? `${baseEndpoint}-online` : baseEndpoint;
        const response = await fetch(finalEndpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        if (response.ok) {
          const data = await response.json();
          const content = data.content;
          accumulatedResponse += content;
        } else {
          console.error('Something went wrong');
        }
      } else {
        let reasoningStartTime = null;
        let reasoningNowTime = null;
        let reasoningEndTime = null;

        const requestBody = {
          messages: conversationHistory,
          model: selectedModel,
          imageBase64: imageBase64,
          max_tokens: maxTokens,
        };
        setImageBase64(null);
        const authToken = await auth.currentUser.getIdToken();
        const baseEndpoint = getEndpoint(modelDetails, hasSubscription);
        const finalEndpoint = isOnline ? `${baseEndpoint}-online` : baseEndpoint;
        const response = await fetch(finalEndpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;
          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data:')) {
              const jsonString = line.slice(5).trim();
              if (jsonString === '[DONE]') {
                done = true;
                break;
              }
              try {
                const { content, reasoning_content, citations } = JSON.parse(jsonString);

                if (citations && Array.isArray(citations)) {
                  lastCitations = citations;
                }

                if (reasoning_content && !reasoningStartTime) {
                  reasoningStartTime = Date.now();
                }
                if (reasoning_content) {
                  accumulatedReasoning += reasoning_content;
                  assistantMessage.reasoning_text = accumulatedReasoning;
                  onMessageSent(assistantMessage, chatId);
                }

                if (content) {
                  if (reasoningStartTime && !reasoningEndTime) {
                    reasoningEndTime = Date.now();
                  }
                  accumulatedResponse += content;
                  updateMessage(accumulatedResponse);
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }

        let reasoningTime = null;
        if (reasoningStartTime && reasoningEndTime) {
          reasoningTime = reasoningEndTime - reasoningStartTime; // in ms
        }
        if (reasoningTime != null) {
          assistantMessage.reasoning_time = formatDuration(reasoningTime);
        }
      }

      if (!isImageGeneration(modelDetails)) {
        assistantMessage.streaming = false;
        assistantMessage.text = accumulatedResponse.trim();
        assistantMessage.reasoning_text = accumulatedReasoning.trim();

        if (lastCitations.length > 0) {
          assistantMessage.citations = lastCitations;
        }

        onMessageSent(assistantMessage, chatId);
        await addMessageToFirestore(chatId, assistantMessage);
        await generateAndUpdateTopic(assistantMessage.text, chatId);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      if (isOnline) {
        setIsOnline(false);
      }
    }
  }, [
    input,
    selectedImage,
    selectedPdf,
    imageBase64,
    selectedChat,
    selectedModel,
    selectedModelName,
    onMessageSent,
    messages,
    hasSubscription,
    messageLimit,
    disableInput,
    navigate
  ]);

  function formatDuration(ms) {
    const totalSeconds = Math.round(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    if (minutes === 0) {
      return `${seconds}s`;
    } else {
      if (seconds === 0) {
        return `${minutes} m`;
      } else {
        return `${minutes}m and ${seconds}s`;
      }
    }
  }

  // --------------------------------------------------
  // Single File Selection
  // --------------------------------------------------
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileType = file.type;
    const isPdf = fileType === 'application/pdf';
    const isImage = fileType.startsWith('image/');

    // if ((!isSonnet && !isGeminiFlash) && isPdf) {
    if (!isSonnet && isPdf) {
      alert('PDFs are only supported in the Sonnet or Gemini 2 Flash model.');
      clearFileState();
      return;
    }

    if (!isPdf && !isImage) {
      alert('Only images are supported (or PDFs with Sonnet).');
      clearFileState();
      return;
    }

    if (isImage) {
      const jpegFile = await convertToJPEG(file);
      setSelectedImage(jpegFile);
      setSelectedPdf(null);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
        setPdfPreviewUrl(null);
        const base64String = reader.result.split(',')[1];
        setImageBase64(base64String);
      };
      reader.readAsDataURL(jpegFile);
    }

    // if (isPdf && (isSonnet || isGeminiFlash)) {
    if (isPdf && isSonnet) {
      setSelectedPdf(file);
      setSelectedImage(null);
      setImageBase64(null);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPdfPreviewUrl(reader.result);
        setImagePreviewUrl(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    clearFileState();
  };

  const triggerFileSelect = () => {
    if (isDeepseek || isOpenAIReasoningWithoutImage) {
      alert("File uploading is not supported for this model.");
      return;
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // --------------------------------------------------
  // Handle typing
  // --------------------------------------------------
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // --------------------------------------------------
  // Firestore updates
  // --------------------------------------------------
  const addMessageToFirestore = async (chatId, message) => {
    const messagesRef = collection(firestore, 'chats', chatId, 'messages');
    const messageData = {
      id: message.id,
      text: message.text,
      role: message.role,
      createdAt: serverTimestamp(),
    };

    if (message.role === 'user') {
      if (message.imageUrl) messageData.imageUrl = message.imageUrl;
      if (message.pdfUrl) {
        messageData.pdfUrl = message.pdfUrl;
        messageData.pdfName = message.pdfName;
      }
      if (message.imageBase64) messageData.imageBase64 = message.imageBase64;
    }

    if (message.role === 'assistant') {
      if (message.reasoning_text) messageData.reasoning_text = message.reasoning_text;
      if (message.citations && message.citations.length > 0) {
        messageData.citations = message.citations;
      }
      if (message.reasoning_time) {
        messageData.reasoning_time = message.reasoning_time;
      }
    }

    await addDoc(messagesRef, messageData);

    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      lastMessageSent: serverTimestamp(),
    });
  };

  const generateAndUpdateTopic = async (responseText, chatId) => {
    const lastUserMessage = messages[messages.length - 2]?.text || '';
    const lastAssistantMessage = responseText;

    const topicPrompt = `
      Based on the following conversation, generate a concise topic of 4-5 words maximum and 30 letters or less:
      User: ${lastUserMessage}
      AI: ${lastAssistantMessage}
      Topic:
    `;

    const authToken = await auth.currentUser.getIdToken();
    const topicResponse = await fetch(
      'https://arrow-ai-cloud-run-123734116924.us-east1.run.app/api/generate-topic',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ prompt: topicPrompt }),
      }
    );

    const result = await topicResponse.json();
    const generatedTopic = result.topic || 'Untitled Chat';

    const chatDocRef = doc(firestore, 'chats', chatId);
    await updateDoc(chatDocRef, {
      topic: generatedTopic,
    });

    updateChatTitle(generatedTopic);
  };

  // --------------------------------------------------
  // Dynamic Textarea Resizing
  // --------------------------------------------------
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        300
      )}px`;
    }
  }, [input]);

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  const isInputEmpty = input.trim() === '';
  const hasAttachment = Boolean(selectedImage || selectedPdf);

  return (
    <div className="max-w-3xl w-full mx-auto">
      <div className="pt-4 px-4 pb-4 w-full bg-transparent dark:bg-transparent transition-colors duration-200">

        {/* If there's a preview (image or PDF), show it on top */}
        {(imagePreviewUrl || pdfPreviewUrl) && (
          <div className="mb-2 relative inline-block">
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Selected"
                className="max-h-32 rounded-md"
              />
            ) : (
              <div className="flex items-center space-x-2 bg-gray-100 dark:bg-gray-800 p-2 rounded-md">
                <FileIcon size={24} className="text-gray-600 dark:text-gray-400" />
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {selectedPdf?.name || 'PDF Document'}
                </span>
              </div>
            )}
            <button
              onClick={handleRemoveFile}
              className="absolute top-1 right-1 bg-gray-800 bg-opacity-50 text-white rounded-full p-1 hover:bg-opacity-75 transition-colors duration-200"
            >
              <X size={16} />
            </button>
          </div>
        )}

        {/* Input Container */}
        <div
          className="
            relative
            flex
            flex-col
            bg-[rgb(209,209,205)]
            dark:bg-[rgb(36,36,36)]
            px-3
            py-3
            rounded-2xl
            focus-within:ring-2
            focus-within:ring-[rgb(199,199,195)]
            dark:focus-within:ring-[rgb(27,27,27)]
          "
        >
          <textarea
            ref={textareaRef}
            rows="1"
            className="
              bg-transparent
              w-full
              py-1
              pl-1
              resize-none
              overflow-y-auto
              focus:outline-none
              text-gray-800
              dark:text-white
              placeholder-gray-600
              dark:placeholder-gray-500
            "
            placeholder={`Message ${selectedModelName}${isImageGeneration(getChatModelByModelString(selectedModel))
                ? " (Image prompt)"
                : ""
              }`}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            disabled={disableInput}
            style={{
              maxHeight: '300px',
              scrollbarWidth: 'thin',
              scrollbarColor: 'rgba(155,155,155,0.5) transparent',
            }}
          />

          <div className="flex items-center justify-between mt-3">
            <div className="flex items-center space-x-2">
              <button
                onClick={triggerFileSelect}
                disabled={disableInput}
                className={`
                  h-7 w-7
                  rounded-full
                  flex
                  items-center
                  justify-center
                  transition-colors
                  duration-200
                  ${isDeepseek
                    ? 'bg-gray-300 dark:bg-[rgb(76,76,76)] text-gray-400 dark:text-gray-500 cursor-not-allowed'
                    : 'bg-gray-200 dark:bg-[rgb(22,22,22)] text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-500'
                  }
                `}
              >
                <Plus size={18} />
              </button>

              {/* The "Online" pill */}
              {modelDetails?.online && (
                <button
                  type="button"
                  onClick={() => setIsOnline(!isOnline)}
                  disabled={disableInput}
                  className={`
      flex items-center px-2 py-1 rounded-full transition-colors duration-200
      ${isOnline
                      ? 'bg-blue-500 text-white border-transparent hover:bg-blue-600'
                      : 'bg-gray-200 dark:bg-[rgb(22,22,22)] text-gray-600 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-500'
                    }
    `}
                >
                  <Globe size={16} className="mr-1" />
                  <span className="text-sm">Online</span>
                </button>
              )}
            </div>

            {/* Send button */}
            <button
              onClick={handleSendMessage}
              disabled={disableInput || (input.trim() === '' && !selectedImage && !selectedPdf)}
              className={`
                h-7 w-7
                rounded-full
                flex
                items-center
                justify-center
                transition-colors
                duration-200
                ${disableInput || (input.trim() === '' && !selectedImage && !selectedPdf)
                  ? 'bg-gray-300 dark:bg-[rgb(76,76,76)] text-gray-400 dark:text-gray-500 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700'
                }
              `}
            >
              <ArrowUp size={18} />
            </button>
          </div>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          className="hidden"
        />
      </div>

      {disableInput && (
        <div className="text-xs text-gray-500 dark:text-gray-400 mb-2 text-center">
          Message limit reached for this chat. Please create a new chat.
        </div>
      )}
    </div>
  );
};

export default MessageInput2;
